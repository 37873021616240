<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">
      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>行程单查询</div>
      </div>

      <el-form :inline="true" :model="pageInfo.data" class="demo-form-inline">
        <el-form-item label="行程单单号">
          <el-input placeholder="行程单单号" v-model="pageInfo.data.serialNo"></el-input>
        </el-form-item>
        <el-form-item label="行程单状态">
          <el-select placeholder="行程单状态" v-model="pageInfo.data.itineraryStatus">
            <el-option v-for="(item,index) in staArr" :key="index" :label="item.name" :value="item.value">{{item.name}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="pageInfo.data.startDate" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="pageInfo.data.endDate" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="预订人姓名">
          <el-input placeholder="预订人姓名" v-model="pageInfo.data.reservationPersonName"></el-input>
        </el-form-item>
        <el-form-item label="预订人电话">
          <el-input placeholder="预订人电话" v-model="pageInfo.data.reservationPersonTelephone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="pageInfo.data = {}">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="uc_con">
      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>行程单列表</div>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="行程单单号" align="center">
          <template #default="scope">
            <span class="cur_p t_num" @click="goDetail(scope.row)">{{scope.row.serialNo}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="approveStatus" label="行程单状态" align="center">
          <template #default="scope">
            <span>{{scope.row.approveStatus ? staArr[scope.row.approveStatus].name : '--'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPurchaseNumber" label="总票数" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="总金额" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span>{{scope.row.totalSellPrice ? scope.row.totalSellPrice/100 : 0}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reservationPersonName" label="预订人姓名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reservationPersonTelephone" label="预订人电话" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="creatorTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="approveTime" label="审核时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="center" width="250">
          <template #default="scope">
            <div class="d_f ali_c j_c">
              <div class="right_btn cur_p" @click="changeRow(scope.row)" v-if="scope.row.approveStatus == null && scope.row.itineraryStatus == 0">
                <img src="../assets/images/sd_xiugai.png" alt="">
                <span>修改</span>
              </div>
              <div class="right_btn cur_p ml_5" @click="payRow(scope.row)" v-if="scope.row.approveStatus == 2 && scope.row.itineraryStatus == 0">
                <img src="../assets/images/tr_zhifu.png" alt="">
                <span>支付</span>
              </div>
              <div class="right_btn cur_p ml_5" @click="cancelRow(scope.row)" v-if="scope.row.approveStatus == null && scope.row.itineraryStatus == 0">
                <img src="../assets/images/tr_quxiao.png" alt="">
                <span>取消</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="t_r mt_12">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:currentPage="pageInfo.pageIndex"
          :page-sizes="$global.pageArr"
          :page-size="pageInfo.pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "trip",
    data() {
      return {
        staArr:[
          {value:0,name:'待提交'},
          {value:1,name:'审核中'},
          {value:2,name:'审核通过'},
          {value:3,name:'审核失败'},
          {value:4,name:'已取消'},
        ],
        list:[],
        total:0,
        pageInfo:{
          pageIndex:1,
          pageSize:10,
          data:{
            itineraryType:1,
            serialNo :'',
            itineraryStatus:null,//0流程中 1完成 2取消
            startDate:'',
            endDate:'',
            reservationPersonName:'',
            reservationPersonTelephone:'',
            // businessInfoId:sessionStorage.getItem('businessInfoId'),
          }
        },
      }
    },
    created() {
      this.search();
    },
    methods: {
      search(){
        this.$api.queryItineraryPage(this.pageInfo).then(res => {
          this.total = res.data.recordCount;
          let list = [];
          res.data.rows.map(item => {
            let obj = {...item};
            if(item.itineraryStatus == 2){
              obj.approveStatus = 4
            }
            list.push({...obj})
          });
          this.list = [...list]
        })
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.pageInfo.pageIndex = 1;
        this.search();
      },
      handleCurrentChange(val) {
        this.pageInfo.pageIndex = val;
        this.search();
      },
      cancelRow(row){
        this.$api.itineraryCancel({itineraryId:row.itineraryId}).then(res => {
          this.$message.success('取消成功');
          this.search()
        })
      },
      //跳转支付
      payRow(row){
        this.$api.itineraryCreateOrder({itineraryId:row.itineraryId}).then(res => {
          this.$router.push({path:'/pay',query:{id:res.data.orderId,num:res.data.orderNumber}});
        })
      },
      //跳转支付
      changeRow(row){
        this.$router.push('/fillIn?id='+row.itineraryId)
      },
      //跳转详情
      goDetail(row){
        this.$router.push('/tripDetail?id='+row.itineraryId)
      }
    }
  }
</script>

<style scoped lang="scss">
  .t_num{
    color: var(--el-color-primary);
    font-style: italic;
    display: inline-block;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--el-color-primary);
  }

</style>
